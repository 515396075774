import React, { createContext, useState, useEffect, useContext } from "react"
import { onSnapshot, doc, collection } from "firebase/firestore"
import { firestore } from "../firebase/fb"
import { cloneDeep } from "lodash"
const defaultState = {
  holdings: [],
  categories: [],
  ecosystems: [],
  mv: null,
  nav: null,
  performance: null,
  timestamp: null,
  units: null,
}

const PortfolioContext = createContext(defaultState)

export const usePortfolio = () => useContext(PortfolioContext)

const PortfolioProvider = ({ children }) => {
  const [holdings, setHoldings] = useState([])
  const [categories, setCategories] = useState([])
  const [ecosystems, setEcosystems] = useState([])
  const [mv, setMv] = useState(null)
  const [nav, setNav] = useState(null)
  const [performance, setPerformance] = useState(null)
  const [timestamp, setTimestamp] = useState(null)
  const [units, setUnits] = useState(null)

  useEffect(() => {
    const snapshotListener = onSnapshot(
      doc(firestore, "portfolios", "xPoxqJhhaeyKI2W1QnWP"),
      q => {
        setHoldings(q.data().holdings)
        setMv(q.data().mv)
        setUnits(q.data().units)
        setNav(q.data().nav)
        setTimestamp(q.data().timestamp.toDate())
        setPerformance({
          eold: q.data().nav.current / q.data().nav.eold - 1,
          eolw: q.data().nav.current / q.data().nav.eolw - 1,
          eolm: q.data().nav.current / q.data().nav.eolm - 1,
          eoly: q.data().nav.current / q.data().nav.eoly - 1,
          si: q.data().nav.current - 1,
        })
      }
    )

    return () => snapshotListener()
  }, [])

  useEffect(() => {
    const snapshotListener = onSnapshot(
      collection(firestore, "categories"),
      q => {
        setCategories(
          q.docs.map(doc => {
            const newCat = cloneDeep(doc.data())
            newCat["id"] = doc.id
            newCat["ref"] = doc.ref
            return newCat
          })
        )
      }
    )

    return () => snapshotListener()
  }, [])

  useEffect(() => {
    const snapshotListener = onSnapshot(
      collection(firestore, "ecosystems"),
      q => {
        setEcosystems(
          q.docs.map(doc => {
            const newEco = cloneDeep(doc.data())
            newEco["id"] = doc.id
            newEco["ref"] = doc.ref
            return newEco
          })
        )
      }
    )

    return () => snapshotListener()
  }, [])

  return (
    <PortfolioContext.Provider
      value={{
        holdings: holdings,
        categories: categories,
        ecosystems: ecosystems,
        nav: nav,
        performance: performance,
        timestamp: timestamp,
        mv: mv,
        units: units,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  )
}

export default PortfolioProvider
