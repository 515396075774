exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-fund-allocation-js": () => import("./../../../src/pages/fund/allocation.js" /* webpackChunkName: "component---src-pages-fund-allocation-js" */),
  "component---src-pages-fund-factsheets-js": () => import("./../../../src/pages/fund/factsheets.js" /* webpackChunkName: "component---src-pages-fund-factsheets-js" */),
  "component---src-pages-fund-performance-js": () => import("./../../../src/pages/fund/performance.js" /* webpackChunkName: "component---src-pages-fund-performance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-assets-js": () => import("./../../../src/pages/research/assets.js" /* webpackChunkName: "component---src-pages-research-assets-js" */),
  "component---src-pages-research-sectors-js": () => import("./../../../src/pages/research/sectors.js" /* webpackChunkName: "component---src-pages-research-sectors-js" */),
  "component---src-pages-research-trading-js": () => import("./../../../src/pages/research/trading.js" /* webpackChunkName: "component---src-pages-research-trading-js" */),
  "component---src-pages-strategy-construction-js": () => import("./../../../src/pages/strategy/construction.js" /* webpackChunkName: "component---src-pages-strategy-construction-js" */),
  "component---src-pages-strategy-philosophy-js": () => import("./../../../src/pages/strategy/philosophy.js" /* webpackChunkName: "component---src-pages-strategy-philosophy-js" */),
  "component---src-pages-strategy-risk-js": () => import("./../../../src/pages/strategy/risk.js" /* webpackChunkName: "component---src-pages-strategy-risk-js" */),
  "component---src-pages-team-about-js": () => import("./../../../src/pages/team/about.js" /* webpackChunkName: "component---src-pages-team-about-js" */),
  "component---src-pages-team-blog-js": () => import("./../../../src/pages/team/blog.js" /* webpackChunkName: "component---src-pages-team-blog-js" */),
  "component---src-pages-team-contact-js": () => import("./../../../src/pages/team/contact.js" /* webpackChunkName: "component---src-pages-team-contact-js" */),
  "component---src-templates-posts-layout-js": () => import("./../../../src/templates/posts-layout.js" /* webpackChunkName: "component---src-templates-posts-layout-js" */)
}

