import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import firebaseConfig from "./config"

const app = initializeApp(firebaseConfig)

/* if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} */

const firestore = getFirestore(app)
const auth = getAuth(app)

export { app, firestore, auth }
