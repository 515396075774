import { MDXProvider } from "@mdx-js/react"
import React from "react"
import AuthProvider from "./src/context/AuthContext"
import PortfolioProvider from "./src/context/PortfolioContext"

const Footnotes = props => {
  console.log(props)
  const fns = props.children[1].props.children.length
    ? props.children[1].props.children
    : [props.children[1].props.children]
  console.log(fns)
  return (
    <div className="">
      <h3 className="border-t w-full">References</h3>
      <table className="align-top">
        <tbody>
          {fns.map(fn => {
            return (
              <tr key={fn.props.id}>
                <td className="text-white font font-extralight">
                  {fn.props.id.slice(3)}.
                </td>
                <td className="">
                  <a
                    className="text-white font-extralight"
                    href={fn.props.children[0].props.href}
                  >
                    {fn.props.children[0].props.href}
                  </a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
const components = {
  wrapper: ({ children, ...props }) => {
    const updatedChildren = children.map(child => {
      if (!child.props) {
        return null
      }
      if (child.props.className === "footnotes") {
        // Since we only have one element that will ever match this
        // the key doesn't matter, but react will yell without a key.
        console.log(child.props)
        return <Footnotes key={1} {...child.props} />
      }
      return child
    })
    return <>{updatedChildren}</>
  },
}

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <PortfolioProvider>
      <MDXProvider components={components}>{element}</MDXProvider>
    </PortfolioProvider>
  </AuthProvider>
)
