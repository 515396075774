const firebaseConfig = {
  apiKey: "AIzaSyACC9bHV7a84GFr2uuLLlhwE2mCvRRKcrQ",
  authDomain: "the-simple-crypto-fund.firebaseapp.com",
  projectId: "the-simple-crypto-fund",
  storageBucket: "the-simple-crypto-fund.appspot.com",
  messagingSenderId: "585489005325",
  appId: "1:585489005325:web:fe1260bf940eb465aa0e44",
}

export default firebaseConfig
